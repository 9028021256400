import './App.css';

function App() {
  const displayEmail = () => {
    let emailE = 'b-average.com';
    emailE = 'mailto:contact@' + emailE;
    return emailE;
  };

  return (
    <div className="App">
      <div className="banner">
        <img
          src="/images/b-average-logo.png"
          alt="b average logo"
          className="logo"
        />
      </div>
      <header className="App-header">
        <ul>
          <li>
            <a href="http://pinocoin.xyz" target="_blank" rel="noreferrer">
              Pino coin
            </a>
          </li>
          <li>
            <a href="http://billygrams.com" target="_blank" rel="noreferrer">
              Billy Grams
            </a>
          </li>
          <li>
            <a
              href="http://trudeauisaclown.com"
              target="_blank"
              rel="noreferrer">
              Trudeau is a Clown
            </a>
          </li>
          <li>
            <a href="http://roshambo.app" target="_blank" rel="noreferrer">
              Roshambo
            </a>
          </li>
          <li>
            <a href="http://storybookart.ai" target="_blank" rel="noreferrer">
              Storybook AI
            </a>
          </li>
          <li>
            <a href={displayEmail()} className="contact">
              CONTACT US
            </a>
          </li>
        </ul>
      </header>

      <footer>
        &copy; <strong>{new Date().getFullYear()} B AVERAGE INC.</strong>
      </footer>
    </div>
  );
}

export default App;
